import {
  JoinDeliveryFleetActions,
  SetInputValidated,
  SET_INPUT_JOIN_DELIVERY_FLEET,
  SET_FORM_LOADING_JOIN_DELIVERY_FLEET,
  SetFormLoading,
  SET_PHONE_INPUT_JOIN_DELIVERY_FLEET,
  SetPhoneInputValidated,
  SET_FORM_SUCCESS_JOIN_DELIVERY_FLEET,
  SetFormSuccess,
  SetCheckboxValidated,
  SET_CHECKBOX_JOIN_DELIVERY_FLEET,
  SET_GENERAL_ERROR_JOIN_DELIVERY_FLEET,
  SetGeneralErrorMessage,
  RESET_FORM_JOIN_DELIVERY_FLEET,
} from './actions';

export type JoinDeliveryFleetData = {
  isLoading: boolean;
  isSuccess: boolean;
  generalError: {
    message?: string;
  };
  recaptcha: {
    value: string;
    error: string;
  };
  title: {
    value: string;
    error: string;
  };
  firstName: {
    value: string;
    error: string;
  };
  lastName: {
    value: string;
    error: string;
  };
  phoneNumber: {
    countryCode: string;
    number: string;
    error: string;
  };
  vehicle: {
    value: string;
    error: string;
  };
  email: {
    value: string;
    error: string;
  };
  currentDesignation: {
    value: string;
    error: string;
  };
  currentLocation: {
    value: string;
    error: string;
  };
  resume: {
    value?: File | undefined;
    error: string;
  };
  inviteCode: {
    value: string;
    error: string;
  };
  isPolicyChecked: {
    value: {[key: string]: boolean};
    error: string;
  };
}

export const initialState: JoinDeliveryFleetData = {
  isLoading: false,
  isSuccess: false,
  generalError: {
    message: '',
  },
  recaptcha: {
    value: '',
    error: '',
  },
  title: {
    value: '',
    error: '',
  },
  firstName: {
    value: '',
    error: '',
  },
  lastName: {
    value: '',
    error: '',
  },
  phoneNumber: {
    countryCode: '+971',
    number: '',
    error: '',
  },
  vehicle: {
    value: '',
    error: '',
  },
  email: {
    value: '',
    error: '',
  },
  currentDesignation: {
    value: '',
    error: '',
  },
  currentLocation: {
    value: '🇦🇪 United Arab Emirates',
    error: '',
  },
  resume: {
    value: undefined,
    error: '',
  },
  inviteCode: {
    value: '',
    error: '',
  },
  isPolicyChecked: {
    value: {},
    error: '',
  },
};

const joinDeliveryFleet = (
  state: JoinDeliveryFleetData = initialState,
  action: JoinDeliveryFleetActions,
): JoinDeliveryFleetData => {
  switch (action.type) {
    case SET_INPUT_JOIN_DELIVERY_FLEET:
      return {
        ...state,
        ...(action as SetInputValidated).payload,
      };
    case SET_PHONE_INPUT_JOIN_DELIVERY_FLEET:
      return {
        ...state,
        ...(action as SetPhoneInputValidated).payload,
      };
    case SET_FORM_LOADING_JOIN_DELIVERY_FLEET: {
      return {
        ...state,
        isLoading: (action as SetFormLoading).payload.isLoading,
      };
    }
    case SET_FORM_SUCCESS_JOIN_DELIVERY_FLEET: {
      return {
        ...state,
        isSuccess: (action as SetFormSuccess).payload.isSuccess,
      };
    }
    case SET_CHECKBOX_JOIN_DELIVERY_FLEET:
      return {
        ...state,
        ...(action as SetCheckboxValidated).payload,
      };
    case SET_GENERAL_ERROR_JOIN_DELIVERY_FLEET: {
      return {
        ...state,
        generalError: {
          message: (action as SetGeneralErrorMessage).payload,
        },
      };
    }
    case RESET_FORM_JOIN_DELIVERY_FLEET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default joinDeliveryFleet;
